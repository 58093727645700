import React, { useState, useEffect } from 'react';
import {
  Autocomplete,
  Box,
  TextField,
  Typography,
  Switch,
  Button,
  IconButton,
  Chip,
  FormControl,
  CircularProgress,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  useFetchSlowChartDataQuery,
  useUpdateChartMutation,
} from '../../apis/chart';
import { TRIAGE } from '../../utils';
import { useParams } from 'react-router-dom';

const ChartDetailsEditable = ({ chart, onSave }) => {
  const { id } = useParams();
  const [encounters, setEncounters] = useState([...chart.encounters]);
  const [newEncounter, setNewEncounter] = useState({
    date_of_service_start: '',
    date_of_service_end: '',
    provider_name: '',
    medical_center_name: '',
  });
  const [showEncounterForm, setShowEncounterForm] = useState(false);
  const [outOfScopeOtherReason, setOutOfScopeOtherReason] = useState('');
  const [selectedOosReasons, setSelectedOosReasons] = useState([]);
  const [isOutOfScopeModalOpen, setIsOutOfScopeModalOpen] = useState(false);

  const { isLoading } = useFetchSlowChartDataQuery(id);

  const [updateChart, { isLoading: saving, error: saveError }] =
    useUpdateChartMutation();

  const isOosOtherSelected = selectedOosReasons.some(
    (r) => r === 'Not codable - other',
  );

  useEffect(() => {
    setEncounters([...chart.encounters]);
  }, [chart]);

  const handleDeleteEncounter = (index) => {
    const updatedEncounters = [...encounters];
    updatedEncounters.splice(index, 1);
    setEncounters(updatedEncounters);
  };

  const handleAddEncounter = () => {
    if (!newEncounter.date_of_service_start) {
      return;
    }
    if (!newEncounter.date_of_service_end) {
      newEncounter.date_of_service_end = newEncounter.date_of_service_start;
    }
    setEncounters([...encounters, newEncounter]);
    setNewEncounter({ date_of_service_start: '', date_of_service_end: '' });
    setShowEncounterForm(false);
  };

  const handleSubmit = async () => {
    const isSubmittable = encounters.some((enc) => enc.is_submittable);
    const hasOosReasons = selectedOosReasons.length > 0;
    const requiresOosReasons = !isSubmittable && !hasOosReasons;
    if (requiresOosReasons) {
      setIsOutOfScopeModalOpen(true);
      return;
    }

    try {
      // Update chart and encounters
      const chartUpdateData = {
        id: chart.uuid,
        encounters,
        current_wf_step: TRIAGE,
        is_submittable: isSubmittable,
        out_of_scope_reasons: selectedOosReasons,
        out_of_scope_reason_other: outOfScopeOtherReason,
      };
      await updateChart(chartUpdateData);

      onSave();
    } catch (err) {
      console.error('Error saving data:', err);
    } finally {
      handleOosModalClose();
    }
  };

  const outOfScopeOptions = [
    'Not codable - no clinical info',
    'Not codable - not a visit',
    'Not codable - not signed',
    'Not codable - incomplete chart/failed split',
    'Not codable - other',
    'Codable - Not a 2023 DOS',
    'Codable - Not a F2F Encounter',
  ];

  const handleOutOfScopeOtherChange = (e) => {
    const value = e.target.value;
    setOutOfScopeOtherReason(value || '');
  };

  const handleOosModalClose = () => {
    setSelectedOosReasons([]);
    setOutOfScopeOtherReason('');
    setIsOutOfScopeModalOpen(false);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        backgroundColor: '#f9f9f9',
        padding: 3,
        borderRadius: 2,
        border: '1px solid #ddd',
      }}
    >
      {/* Encounters */}
      <Box>
        <Typography variant="h6" sx={{ mb: 1 }}>
          Encounters
        </Typography>
        <Typography variant="body1" sx={{ mb: 1 }}>
          Toggle for all true service dates in the chart and remove any errors.
          A submittable encounter is a signed, face to face visit by a
          physician.
        </Typography>
        {encounters.map((encounter, index) => (
          <Box
            key={encounter.id}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              marginBottom: 2,
            }}
          >
            <Chip
              label={`${encounter.date_of_service_start} - ${encounter.date_of_service_end}`}
              color="primary"
              variant="outlined"
              sx={{ padding: '4px 8px', fontSize: '0.875rem' }}
              data-testid="encounter-chip"
            />
            <TextField
              label="Provider Name"
              value={encounter.provider_name}
              onChange={(e) => {
                const updatedEncounters = encounters.map((enc, i) =>
                  i === index ? { ...enc, provider_name: e.target.value } : enc,
                );
                setEncounters(updatedEncounters);
              }}
              size="small"
              variant="outlined"
            />
            <TextField
              label="Medical Center Name"
              value={encounter.medical_center_name}
              onChange={(e) => {
                const updatedEncounters = encounters.map((enc, i) =>
                  i === index
                    ? { ...enc, medical_center_name: e.target.value }
                    : enc,
                );
                setEncounters(updatedEncounters);
              }}
              size="small"
              variant="outlined"
            />
            <Box>
              <Typography variant="body1" display="inline">
                Is Submittable
              </Typography>
              <Switch
                checked={encounter.is_submittable}
                onChange={(e) => {
                  setEncounters((prev) =>
                    prev.map((enc) =>
                      enc.id === encounter.id
                        ? { ...enc, is_submittable: e.target.checked }
                        : enc,
                    ),
                  );
                }}
              />
            </Box>
            <IconButton
              color="error"
              onClick={() => handleDeleteEncounter(index)}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        ))}
        {showEncounterForm ? (
          <Box sx={{ display: 'flex', gap: 2, marginTop: 2 }}>
            <TextField
              label="Start Date"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={newEncounter.date_of_service_start}
              onChange={(e) =>
                setNewEncounter((prev) => ({
                  ...prev,
                  date_of_service_start: e.target.value,
                }))
              }
              size="small"
            />
            <TextField
              label="End Date"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={newEncounter.date_of_service_end}
              onChange={(e) =>
                setNewEncounter((prev) => ({
                  ...prev,
                  date_of_service_end: e.target.value,
                }))
              }
              size="small"
            />
            <TextField
              label="Provider Name"
              value={newEncounter.provider_name}
              onChange={(e) =>
                setNewEncounter((prev) => ({
                  ...prev,
                  provider_name: e.target.value,
                }))
              }
              size="small"
            />
            <TextField
              label="Medical Center Name"
              value={newEncounter.medical_center_name}
              onChange={(e) =>
                setNewEncounter((prev) => ({
                  ...prev,
                  medical_center_name: e.target.value,
                }))
              }
              size="small"
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddEncounter}
            >
              Add
            </Button>
          </Box>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setShowEncounterForm(true)}
            sx={{ marginTop: 2 }}
          >
            Add Encounter
          </Button>
        )}
      </Box>

      {/* Confirm Button */}
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={saving || isLoading}
        startIcon={
          isLoading ? <CircularProgress size={16} color="inherit" /> : null
        }
      >
        {saving ? 'Saving...' : 'Confirm'}
      </Button>

      {/* Confirm Out Of Scope Reasons Dialog */}
      <Dialog
        open={isOutOfScopeModalOpen}
        onClose={handleOosModalClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Confirm Out Of Scope Reasons</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            Please select all out of scope reasons that apply
          </DialogContentText>
          <Box sx={{ flex: 1 }}>
            <Box>
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  options={outOfScopeOptions}
                  getOptionLabel={(option) => option}
                  value={selectedOosReasons}
                  onChange={(_, newValue) => {
                    setSelectedOosReasons(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Out of Scope Reasons"
                      placeholder="Select Out of Scope Reasons"
                      required
                    />
                  )}
                />
              </FormControl>
              {isOosOtherSelected && (
                <TextField
                  label="Specify Other Reason"
                  value={outOfScopeOtherReason}
                  onChange={handleOutOfScopeOtherChange}
                  fullWidth
                  required
                  sx={{ marginTop: 2 }}
                />
              )}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOosModalClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            autoFocus
            disabled={
              !selectedOosReasons.length ||
              (isOosOtherSelected && !outOfScopeOtherReason)
            }
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* Error Display */}
      {saveError && (
        <Typography color="error" sx={{ marginTop: 2 }}>
          Failed to save changes: {saveError.message}
        </Typography>
      )}
    </Box>
  );
};

export default ChartDetailsEditable;
