import React, { useState, useEffect, useCallback } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useFetchChartsQuery, useDeleteChartMutation } from '../../apis/chart';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LaunchIcon from '@mui/icons-material/Launch';
import { filenameComparator, COMPLETED } from '../../utils';

const CompletedCharts = ({ chartSource, debouncedPatientFilter }) => {
  const navigate = useNavigate();

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 50,
  });

  const { data, error, isLoading, isFetching, refetch } = useFetchChartsQuery({
    page: paginationModel.page + 1,
    pageSize: paginationModel.pageSize,
    wf_step: COMPLETED,
    patient: debouncedPatientFilter,
    chartSource,
  });

  const [deleteChart] = useDeleteChartMutation();
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  useEffect(() => {
    refetch();
  }, [paginationModel, refetch]);

  const handlePaginationModelChange = useCallback(
    (newModel) => {
      if (newModel.pageSize !== paginationModel.pageSize) {
        setPaginationModel({ ...newModel, page: 0 });
      } else {
        setPaginationModel(newModel);
      }
    },
    [paginationModel.pageSize],
  );

  const handleDelete = async () => {
    await deleteChart(selectedId);
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedId(null);
  };

  const handleOpenDetails = (id) => {
    navigate(`/chart/${id}}`);
  };
  const handleOpenDetailsNewTab = (id) => {
    window.open(`/chart/${id}`, '_blank');
  };

  const columns = [
    {
      field: 'date_of_service',
      headerName: 'DOS',
      flex: 1,
      renderCell: (params) =>
        dayjs(params.row.date_of_service).format('MM-DD-YYYY'),
    },
    {
      field: 'patient',
      headerName: 'Patient',
      flex: 1,
    },
    {
      field: 'file_gcs_location',
      headerName: 'Filename',
      flex: 2,
      valueGetter: (value, row) => row.file_gcs_location,
      sortComparator: filenameComparator,
    },
    {
      field: 'finalziedByDetails',
      headerName: 'Finalized By (Total Codes)',
      flex: 2,
      renderCell: (params) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <Typography
            variant="body2"
            sx={{ textAlign: 'center', whiteSpace: 'nowrap' }}
          >
            {params.value
              .map((detail) => `${detail.username} (${detail.codes})`)
              .join(', ')}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'open',
      headerName: 'Open',
      sortable: false,
      width: 80,
      renderCell: (params) => (
        <IconButton
          onClick={() => handleOpenDetails(params.id)}
          data-testid={`chart-detail-button-${params.id}`}
        >
          <OpenInNewIcon />
        </IconButton>
      ),
    },
    {
      field: 'openNewTab',
      headerName: 'New Tab',
      sortable: false,
      width: 80,
      renderCell: (params) => (
        <Tooltip title="New Tab">
          <IconButton
            onClick={() => handleOpenDetailsNewTab(params.id)}
            aria-label="Open in New Tab"
          >
            <LaunchIcon />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  // Build the rows from the API data
  const rows =
    data?.data?.map((chart) => {
      // We'll gather the Human coder usernames
      const finalCodedChart = chart.final_coded_chart;
      const username = finalCodedChart.coder_details?.username;
      const codes = finalCodedChart.coded_chart_code_references?.length || 0;

      return {
        id: chart.uuid,
        date_of_service: chart.date_of_service,
        file_gcs_location: chart.file_gcs_location,
        patient: chart.patient ? `${chart.patient.name}` : 'Unknown',
        finalziedByDetails: [{ username, codes }],
      };
    }) || [];

  if (isLoading || isFetching) return <div>Loading completed charts...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <>
      <DataGrid
        pagination
        rows={rows}
        columns={columns}
        rowsPerPageOptions={[25, 50, 100]}
        paginationMode="server"
        rowCount={data?.total || 0}
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationModelChange}
        pageSizeOptions={[25, 50, 100]}
        initialState={{
          sorting: {
            sortModel: [
              { field: 'file_gcs_location', sort: 'asc' },
              { field: 'patient', sort: 'asc' },
            ],
          },
        }}
      />

      {/* Confirm Deletion Dialog */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this chart? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CompletedCharts;
