import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ChartDetailsEditable from './ChartDetailsEditable';

const TriageModal = ({ chart, onSave, isOpen, onClose }) => {
  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Re-triage chart</DialogTitle>
      <DialogContent>
        <ChartDetailsEditable
          chart={chart}
          onSave={() => {
            onSave();
            onClose();
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TriageModal;
