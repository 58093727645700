import React, { useState, useEffect } from 'react';
import { Alert, Box, Typography, Grid, Snackbar } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import theme from '../../../styles/theme';
import FirstPassConditionsList from './FirstPassConditionsList';
import ICDCodeSearch from '../ICDCodeSearch';
import { useFetchChartDetailsQuery } from '../../../apis/chart';
import { useParams } from 'react-router-dom';
import FirstPassFinalizedCodes from './FirstPassFinalizedCodes';

const FirstPassCompletedCodesBuilder = ({
  highlightedText,
  handleCodeClick,
  humanCodedChart,
}) => {
  const { id } = useParams();
  const { data: chart } = useFetchChartDetailsQuery(id);

  const codedCharts = chart?.coded_charts || [];

  // Separate AI and Human CodedCharts
  const aiCodedChart =
    codedCharts.find((codedChart) => codedChart.source === 'AI') || {};

  const [completedCodes, setCompletedCodes] = useState([]);
  const [userSelectedCodes, setUserSelectedCodes] = useState([]);
  const [alertOpen, setAlertOpen] = useState(false);

  useEffect(() => {
    if (humanCodedChart) {
      const confirmedCodes = humanCodedChart.coded_chart_code_references || [];

      const formattedCompletedCodes = confirmedCodes.map((code) => ({
        ...code.icd_code,
      }));
      setCompletedCodes(formattedCompletedCodes);
    } else {
      setCompletedCodes([]);
    }
  }, [humanCodedChart]);

  // Group AI Identified Conditions
  const groupAICodesByCondition = (codedChart) => {
    if (!codedChart.coded_chart_code_references) return [];

    return codedChart.coded_chart_code_references.reduce((acc, ref) => {
      const conditionName = ref.condition?.name || 'Unknown';
      const highlightText = ref.condition_highlight_text || '';
      const rationale = ref.condition_rationale || '';

      const existingGroup = acc.find(
        (group) => group.condition === conditionName,
      );

      if (existingGroup) {
        existingGroup.codes.push(ref.icd_code);
        existingGroup.full_ref.push(ref);
      } else {
        acc.push({
          condition: conditionName,
          codes: [ref.icd_code],
          full_ref: [ref],
          highlight_text: highlightText,
          rationale: rationale,
        });
      }
      return acc;
    }, []);
  };

  const aiIdentifiedConditions = groupAICodesByCondition(aiCodedChart);

  // Handle adding a code manually
  const handleAddCode = async (code) => {
    setUserSelectedCodes((prev) => [
      ...prev,
      {
        ...code,
        user_added: true,
      },
    ]);
  };

  const handleMoveToCompleted = (code) => {
    if (!humanCodedChart?.is_processed) {
      setCompletedCodes((prev) => [
        ...prev,
        {
          ...code,
        },
      ]);
    } else {
      setAlertOpen(true);
    }
  };

  // Handle removing a code from finalized
  const handleRemoveFromCompleted = (code) => {
    if (!humanCodedChart?.is_processed) {
      setCompletedCodes((prev) =>
        prev.filter((item) => item.code !== code.code),
      );
    } else {
      setAlertOpen(true);
    }
  };

  const onDragEnd = (result) => {
    if (humanCodedChart?.is_processed || !result.destination) return;
    const reorderedCodes = Array.from(completedCodes);
    const [removed] = reorderedCodes.splice(result.source.index, 1);
    reorderedCodes.splice(result.destination.index, 0, removed);
    setCompletedCodes(reorderedCodes);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          padding: 2,
          border: '1px solid #ccc',
          borderRadius: 2,
          height: '100%',
          overflow: 'hidden',
        }}
      >
        <ICDCodeSearch
          onAddCode={handleAddCode}
          aiIdentifiedCodes={
            aiCodedChart.coded_chart_code_references?.map(
              (ref) => ref.icd_code,
            ) || []
          }
        />

        <Grid container spacing={2} sx={{ height: 'calc(100% - 80px)' }}>
          {/* Suggested Codes (AI) */}
          <Grid item xs={12} md={6} sx={{ height: '100%', overflowY: 'auto' }}>
            <Typography variant="h6" sx={{ marginLeft: 2, marginBottom: 1 }}>
              AI Suggested Codes
            </Typography>
            <Box
              sx={{
                padding: 2,
                border: '1px solid #ccc',
                backgroundColor: '#f9f9f9',
              }}
            >
              <FirstPassConditionsList
                aiIdentifiedConditions={aiIdentifiedConditions}
                completedCodes={completedCodes}
                userSelectedCodes={userSelectedCodes}
                handleMoveToCompleted={handleMoveToCompleted}
                handleCodeClick={handleCodeClick}
                highlightedText={highlightedText}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sx={{ height: '100%', overflowY: 'auto' }}>
            <FirstPassFinalizedCodes
              completedCodes={completedCodes}
              handleRemoveFromCompleted={handleRemoveFromCompleted}
              aiIdentifiedConditions={aiIdentifiedConditions || []}
              userSelectedCodes={userSelectedCodes}
              onDragEnd={onDragEnd}
              humanCodedChart={humanCodedChart}
            />
          </Grid>
        </Grid>

        <Snackbar
          open={alertOpen}
          autoHideDuration={6000}
          onClose={() => setAlertOpen(false)}
        >
          <Alert
            onClose={() => setAlertOpen(false)}
            severity="warning"
            sx={{ width: '100%' }}
          >
            The chart is locked. No changes can be made.
          </Alert>
        </Snackbar>
      </Box>
    </ThemeProvider>
  );
};

export default FirstPassCompletedCodesBuilder;
