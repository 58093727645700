import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const apiUrl = process.env.REACT_APP_API_URL;

const baseQuery = fetchBaseQuery({
  baseUrl: `${apiUrl}/api/`,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

export const userApiSlice = createApi({
  reducerPath: 'userApi',
  baseQuery,
  endpoints: (builder) => ({
    fetchUsers: builder.query({
      query: () => 'users/',
    }),
    fetchPermissions: builder.query({
      query: () => 'permissions/',
    }),
    fetchGroups: builder.query({
      query: () => 'groups/',
    }),
    fetchUserById: builder.query({
      query: (userId) => `users/${userId}/`,
    }),
    createUser: builder.mutation({
      query: (newUser) => {
        return {
          url: 'users/',
          method: 'POST',
          body: newUser,
        };
      },
    }),
    updateUser: builder.mutation({
      query: (updatedUser) => {
        return {
          url: `users/${updatedUser.id}/`,
          method: 'PUT',
          body: updatedUser,
        };
      },
    }),
    deleteUser: builder.mutation({
      query: (userId) => ({
        url: `users/${userId}/`,
        method: 'DELETE',
      }),
    }),
    getUser: builder.query({
      query: () => 'user/',
    }),
  }),
});

export const {
  useFetchUsersQuery,
  useFetchUserByIdQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useFetchGroupsQuery,
  useGetUserQuery,
} = userApiSlice;
