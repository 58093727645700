import React, { useMemo, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Snackbar,
  Typography,
} from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CodeCard from '../CodeCard';
import {
  useCreateOrUpdateCodedChartMutation,
  useFetchChartDetailsQuery,
  useFetchSlowChartDataQuery,
} from '../../../apis/chart';
import RationaleModal from "./RationaleModal";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { useGetUserQuery } from '../../../apis/users';

const SecondPassFinalizedCodes = ({
  completedCodes,
  handleRemoveFromCompleted,
  aiIdentifiedConditions,
  userSelectedCodes,
  onDragEnd,
  humanCodedChart,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();

  // UI State
  const [modalOpen, setModalOpen] = useState(false);
  const [rationaleData, setRationaleData] = useState([]);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');

  const [otherCodesOpen, setOtherCodesOpen] = useState(false);

  const [createOrUpdateCodedChart, { isLoading: isLoadingCodedChartMutation }] =
    useCreateOrUpdateCodedChartMutation();
  const {
    data: chart,
    isFetching: isFetchingChart,
    refetch: refetchDetail,
  } = useFetchChartDetailsQuery(id);
  const { data: slowData } = useFetchSlowChartDataQuery(id);

  const { data: currentUser } = useGetUserQuery();

  // Choose the coded chart for the current user
  const userSpecificCodedChart = useMemo(() => {
    if (!chart?.coded_charts || !currentUser?.username) return null;

    return (
      chart.coded_charts.find(
        (cc) =>
          cc.source === 'Human' &&
          cc.coder_details?.username === currentUser.username,
      ) || null
    );
  }, [chart, currentUser]);

  // Separate codes into HCC vs other
  const hccCodes = completedCodes.filter((code) => code?.hcc_mapping?.has_hcc);
  const otherCodes = completedCodes.filter((code) => !code?.hcc_mapping?.has_hcc);

  // Modal logic
  const handleFinalizeClick = () => {
    setRationaleData(
      completedCodes.map((code) => ({
        code: code.code,
        description: code.description || '',
        rationale: code?.full_ref?.second_pass_rationale || '',
      })),
    );
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleRationaleChange = (index, value) => {
    setRationaleData((prev) => {
      const updated = [...prev];
      updated[index].rationale = value;
      return updated;
    });
  };

  const handleModalSubmit = async () => {
    try {
      await createOrUpdateCodedChart({
        id: userSpecificCodedChart?.uuid,
        chart: chart.uuid,
        source: 'Human',
        confirmed_codes: rationaleData.map(({ code, rationale }) => ({
          code,
          rationale,
        })),
        processing_time: 0,
        is_processed: true,
      }).unwrap();

      refetchDetail();
      setModalOpen(false);
      setAlertMessage('Successfully finalized codes');
      setAlertSeverity('success');

      if (slowData?.next_chart) {
        navigate(`/chart/${slowData?.next_chart}`);
      }
    } catch (error) {
      console.error('Failed to finalize codes:', error);
      setAlertMessage(
        'Failed to finalize codes. Please refresh the page and try again.',
      );
      setAlertSeverity('error');
    }
  };

  const handleUnlockCodes = async () => {
    try {
      await createOrUpdateCodedChart({
        id: userSpecificCodedChart?.uuid,
        chart: chart.uuid,
        is_processed: false,
      }).unwrap();
      refetchDetail();
      setAlertMessage('Successfully unlocked codes!');
      setAlertSeverity('success');
    } catch (error) {
      console.error('Failed to unlock codes:', error);
      setAlertMessage(
        'Failed to unlock codes. Please refresh the page and try again.',
      );
      setAlertSeverity('error');
    }
  };

  const toggleOtherCodes = () => {
    setOtherCodesOpen((prev) => !prev);
  };

  return (
    <Box>
      {/* Header Section */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginLeft: 2,
          marginBottom: 1,
        }}
      >
        <Typography variant="h6">Finalized Codes</Typography>
        <Button
          variant="contained"
          color="primary"

                  sx={{
          marginBottom: -1,
        }}
          onClick={
            humanCodedChart?.is_processed
              ? handleUnlockCodes
              : handleFinalizeClick
          }
          disabled={isLoadingCodedChartMutation || isFetchingChart}
          startIcon={
            (isLoadingCodedChartMutation || isFetchingChart) && (
              <CircularProgress size={14} />
            )
          }
        >
          {humanCodedChart?.is_processed ? 'Unlock' : 'Finalize'}
        </Button>
      </Box>

      {/* Snackbar */}
      <Snackbar
        open={!!alertMessage}
        autoHideDuration={3000}
        onClose={() => setAlertMessage('')}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setAlertMessage('')}
          severity={alertSeverity}
          sx={{ width: '100%' }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>

      <Box sx={{ padding: 2, border: '1px solid #ccc', backgroundColor: '#f9f9f9' }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', paddingBottom: '10px' }}>
                    <AssignmentIcon fontSize="small" sx={{ mr: 1 }} />
                    {`For Review (${hccCodes.length})`}
                  </Typography>

          <Droppable droppableId="hccCodes">
            {(provided) => (
              <Box
                ref={provided.innerRef}
                {...provided.droppableProps}
                sx={{ minHeight: '80%', overflowY: 'auto', mb: 2 }}
              >
                {hccCodes.map((code, index) => (
                  <Draggable
                    key={code.code}
                    draggableId={`hcc-${code.code}`}
                    index={index}
                  >
                    {(provided) => (
                      <Box
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        sx={{ position: 'relative', paddingBottom: '1rem' }}
                      >
                        <CodeCard
                          code={code}
                          action={handleRemoveFromCompleted}
                          chevronLeft
                          aiIdentifiedConditions={aiIdentifiedConditions}
                          userSelectedCodes={userSelectedCodes}
                          chart={chart}
                          reviewable={true}
                        />
                      </Box>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>

          {/* Other Codes Section with Show/Hide */}
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <Typography variant="h6" sx={{ mr: 2 }}>
              {`Other Codes (${otherCodes.length})`}
            </Typography>
            <Button variant="text" onClick={toggleOtherCodes}>
              {otherCodesOpen ? 'Hide' : 'Show'}
            </Button>
          </Box>

          {otherCodesOpen && (
            <Droppable droppableId="otherCodes">
              {(provided) => (
                <Box
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  sx={{ minHeight: '80%', overflowY: 'auto' }}
                >
                  {otherCodes.map((code, index) => (
                    <Draggable
                      key={code.code}
                      draggableId={`other-${code.code}`}
                      index={index}
                    >
                      {(provided) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          sx={{ position: 'relative', paddingBottom: '1rem' }}
                        >
                          <CodeCard
                            code={code}
                            action={handleRemoveFromCompleted}
                            chevronLeft
                            aiIdentifiedConditions={aiIdentifiedConditions}
                            userSelectedCodes={userSelectedCodes}
                            chart={chart}
                          />
                        </Box>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          )}
        </DragDropContext>
      </Box>

      {/* Rationale Modal */}
      <RationaleModal
        open={modalOpen}
        onClose={handleModalClose}
        rationaleData={rationaleData}
        onRationaleChange={handleRationaleChange}
        onSubmit={handleModalSubmit}
      />
    </Box>
  );
};

export default SecondPassFinalizedCodes;
