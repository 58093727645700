import React, { useState, useEffect, useRef } from 'react';
import { Alert, Box, Typography, Grid, Snackbar } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import theme from '../../../styles/theme';
import ICDCodeSearch from '../ICDCodeSearch';
import { useFetchChartDetailsQuery } from '../../../apis/chart';
import { useParams } from 'react-router-dom';
import SecondPassFinalizedCodes from './SecondPassFinalizedCodes';
import SecondPassConditionsList from './SecondPassConditionsList';
import { COMPLETED } from '../../../utils';

const SecondPassCompletedCodesBuilder = ({
  highlightedText,
  handleCodeClick,
  humanCodedChart,
}) => {
  const { id } = useParams();
  const { data: chart } = useFetchChartDetailsQuery(id);

  const codedCharts = chart?.coded_charts || [];
  const aiCodedChart = codedCharts.find((cc) => cc.source === 'AI') || {};
  const humanCodedCharts = codedCharts.filter((cc) => cc.source === 'Human');
  const finalCodedChartCodes = chart?.final_coded_chart?.coded_chart_code_references?.map(fc => fc?.icd_code?.code)
  const workflowStep = chart?.workflow_state;

  const [aggregatorMap, setAggregatorMap] = useState({});
  const [completedCodes, setCompletedCodes] = useState([]);
  const [userSelectedCodes, setUserSelectedCodes] = useState([]);
  const [alertOpen, setAlertOpen] = useState(false);

  const aggregatorRanRef = useRef(false);

  /**
   * 1) Build aggregatorMap from all processed human-coded charts (multi-coder).
   *    Then we also set up the initial completedCodes based on that aggregator map.
   *    Only run once (or when data truly changes).
   */
  useEffect(() => {
    if (aggregatorRanRef.current) return;
    if (!humanCodedCharts.length) return;

    const codeMap = {};

    humanCodedCharts.forEach((codedChart) => {
      if (codedChart.is_processed) {
        const confirmedCodes = codedChart.coded_chart_code_references || [];
        const coderUsername = codedChart.coder_details?.username || 'Unknown';

        confirmedCodes.forEach((codeRef) => {
          const codeVal = codeRef.icd_code?.code;
          if (!codeVal) return;
          const isCompletedStep = workflowStep === COMPLETED ;
          // In completed step, only include codes that are part of the finalCodedChartCodes
          if (isCompletedStep && !finalCodedChartCodes.includes(codeVal)) return;

          if (!codeMap[codeVal]) {
            codeMap[codeVal] = {
              ...codeRef.icd_code, // => { code: 'I10', description: 'Hypertension', etc. }
              condition: codeRef.condition?.name || 'Unknown',
              rationale: codeRef.rationale || '',
              coders: [coderUsername],
              full_ref: codeRef,
            };
          } else {
            if (!codeMap[codeVal].coders.includes(coderUsername)) {
              codeMap[codeVal].coders.push(coderUsername);
            }
          }
        });
      }
    });

    setAggregatorMap(codeMap);

    const aggregatedList = Object.values(codeMap).map((entry) => ({
      ...entry,
      coders: entry.coders.join(', '), // store as string if needed
    }));

    setCompletedCodes(aggregatedList);

    aggregatorRanRef.current = true;
  }, [humanCodedCharts, finalCodedChartCodes, workflowStep]);

  /**
   * 2) Group AI conditions for the left column
   */
  const groupAICodesByCondition = (codedChart) => {
    if (!codedChart.coded_chart_code_references) return [];
    return codedChart.coded_chart_code_references.reduce((acc, ref) => {
      const conditionName = ref.condition?.name || 'Unknown';
      const existing = acc.find((g) => g.condition === conditionName);

      if (existing) {
        existing.codes.push(ref.icd_code);
        existing.full_ref.push(ref);
      } else {
        acc.push({
          condition: conditionName,
          codes: [ref.icd_code],
          full_ref: [ref],
          highlight_text: ref.condition_highlight_text || '',
          rationale: ref.rationale || '',
        });
      }
      return acc;
    }, []);
  };
  const aiIdentifiedConditions = groupAICodesByCondition(aiCodedChart);

  /**
   * 3) Handle user search -> user adds code
   */
  const handleAddCode = (code) => {
    setUserSelectedCodes((prev) => [...prev, { ...code, user_added: true }]);
  };

  /**
   * 4) Move code from left to right if unlocked
   *    We pull the "full" aggregator data from aggregatorMap if available so we don't lose second pass details.
   */
  const handleMoveToCompleted = (code) => {
    if (humanCodedChart?.is_processed) {
      setAlertOpen(true);
      return;
    }
    const key = code.code || code.icd_code;
    if (!key) {
      code.code = 'UNKNOWN_CODE';
    }

    const aggregatorObj = aggregatorMap[key];
    if (aggregatorObj) {
      const mergedObj = {
        ...code,
        ...aggregatorObj,
        code: key,
        coders:
          aggregatorObj.coders?.join?.(', ') || aggregatorObj.coders || '',
      };
      setCompletedCodes((prev) => [...prev, mergedObj]);
    } else {
      setCompletedCodes((prev) => [...prev, { ...code, code: key }]);
    }
  };

  /**
   * 5) Remove code from completed codes if unlocked
   *    We do NOT remove it from aggregatorMap => aggregator data stays in memory for re-adding
   */
  const handleRemoveFromCompleted = (code) => {
    if (humanCodedChart?.is_processed) {
      setAlertOpen(true);
      return;
    }
    setCompletedCodes((prev) => prev.filter((item) => item.code !== code.code));
  };

  /**
   * 6) Reorder codes if unlocked
   */
  const onDragEnd = (result) => {
    if (!result.destination || humanCodedChart?.is_processed) return;
    const reorder = Array.from(completedCodes);
    const [removed] = reorder.splice(result.source.index, 1);
    reorder.splice(result.destination.index, 0, removed);
    setCompletedCodes(reorder);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          padding: 2,
          border: '1px solid #ccc',
          borderRadius: 2,
          height: '100%',
          overflow: 'hidden',
        }}
      >
        <ICDCodeSearch
          onAddCode={handleAddCode}
          aiIdentifiedCodes={
            aiCodedChart.coded_chart_code_references?.map(
              (ref) => ref.icd_code,
            ) || []
          }
        />

        <Grid container spacing={2} sx={{ height: 'calc(100% - 80px)' }}>
          {/* Left column: AI + user codes */}
          <Grid item xs={12} md={6} sx={{ height: '100%', overflowY: 'auto' }}>
            <Typography variant="h6" sx={{ marginLeft: 2, marginBottom: 1 }}>
              AI Suggested Codes
            </Typography>
            <Box
              sx={{
                padding: 2,
                border: '1px solid #ccc',
                backgroundColor: '#f9f9f9',
              }}
            >
              <SecondPassConditionsList
                aiIdentifiedConditions={aiIdentifiedConditions}
                completedCodes={completedCodes}
                userSelectedCodes={userSelectedCodes}
                handleMoveToCompleted={handleMoveToCompleted}
                handleCodeClick={handleCodeClick}
                highlightedText={highlightedText}
                chart={chart}
              />
            </Box>
          </Grid>

          {/* Right column: Completed/finalized codes */}
          <Grid item xs={12} md={6} sx={{ height: '100%', overflowY: 'auto' }}>
            <SecondPassFinalizedCodes
              completedCodes={completedCodes}
              handleRemoveFromCompleted={handleRemoveFromCompleted}
              aiIdentifiedConditions={aiIdentifiedConditions || []}
              userSelectedCodes={userSelectedCodes}
              onDragEnd={onDragEnd}
              humanCodedChart={humanCodedChart}
            />
          </Grid>
        </Grid>

        <Snackbar
          open={alertOpen}
          autoHideDuration={6000}
          onClose={() => setAlertOpen(false)}
        >
          <Alert
            onClose={() => setAlertOpen(false)}
            severity="warning"
            sx={{ width: '100%' }}
          >
            The chart is locked. No changes can be made.
          </Alert>
        </Snackbar>
      </Box>
    </ThemeProvider>
  );
};

export default SecondPassCompletedCodesBuilder;
