import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  useFetchUsersQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useFetchGroupsQuery,
} from '../../apis/users';

function AdminUsers() {
  const { data: users = [], refetch } = useFetchUsersQuery();
  const {
    data: groupsData,
    isLoading: groupsLoading,
    error: groupsError,
  } = useFetchGroupsQuery();

  const groups = groupsData?.results || [];

  const [createUser] = useCreateUserMutation();
  const [updateUser] = useUpdateUserMutation();
  const [deleteUser] = useDeleteUserMutation();

  const [open, setOpen] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [selectedGroups, setSelectedGroups] = useState([]);

  const handleGroupChange = (event) => {
    const value = event.target.value;

    if (value.length === 0) {
      setSelectedGroups(['None']); // Display "None" if roles deselected
    } else {
      setSelectedGroups(value);
    }
  };

  const getUserSchema = () =>
    yup.object().shape({
      username: yup.string().required('Username is required'),
      email: yup
        .string()
        .email('Invalid email address')
        .required('Email is required'),
      password: !editingUser
        ? yup
            .string()
            .min(6, 'Password must be at least 6 characters')
            .required('Password is required')
        : yup.string(),
    });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(getUserSchema()),
    defaultValues: {
      username: '',
      email: '',
      groups: [],
    },
  });

  const handleOpenDialog = (user = null) => {
    setEditingUser(user);
    if (user) {
      reset({
        username: user.username,
        email: user.email,
      });
      setSelectedGroups(
        groups
          .filter((group) => user.groups.includes(group.name))
          .map((group) => group.id),
      );
    } else {
      reset({
        username: '',
        email: '',
      });
      setSelectedGroups([]);
    }
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setEditingUser(null);
  };

  const onSubmit = async (data) => {
    try {
      const updatedGroups =
        selectedGroups && selectedGroups[0] === 'None' ? [] : selectedGroups;

      const payload = { ...data, group_ids: updatedGroups };
      if (editingUser) {
        await updateUser({ id: editingUser.id, ...payload });
      } else {
        await createUser(payload);
      }
      refetch();
      handleCloseDialog();
    } catch (error) {
      console.error('Error saving user:', error);
    }
  };

  const handleDeleteUser = async (userId) => {
    try {
      await deleteUser(userId);
      refetch();
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        User Management
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleOpenDialog()}
      >
        Create New User
      </Button>
      <Box sx={{ marginTop: 3 }}>
        {users?.results?.map((user) => (
          <Box
            key={user.id}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: 2,
              borderBottom: '1px solid #ccc',
            }}
          >
            <Box
              sx={{ display: 'flex', alignItems: 'center', gap: 2, flex: 1 }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  flex: 1,
                }}
              >
                <Typography variant="body1">
                  <strong>User:</strong> {user.username}
                </Typography>
                <Typography variant="body2">
                  <strong>Email:</strong> {user.email}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                <Typography variant="body2">
                  <strong>Roles:</strong>{' '}
                  {user.groups.length > 0 ? user.groups.join(', ') : 'None'}
                </Typography>
              </Box>
            </Box>
            <Box>
              <IconButton
                onClick={() => handleOpenDialog(user)}
                color="primary"
              >
                <Edit />
              </IconButton>
              <IconButton
                onClick={() => handleDeleteUser(user.id)}
                color="secondary"
              >
                <Delete />
              </IconButton>
            </Box>
          </Box>
        ))}
      </Box>

      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>
          {editingUser ? 'Edit User' : 'Create New User'}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              {...register('username')}
              autoFocus
              margin="dense"
              label="Username"
              type="text"
              fullWidth
              error={!!errors.username}
              helperText={errors.username?.message}
            />
            <TextField
              {...register('email')}
              margin="dense"
              label="Email Address"
              type="email"
              fullWidth
              error={!!errors.email}
              helperText={errors.email?.message}
            />
            {!editingUser && (
              <TextField
                {...register('password')}
                margin="dense"
                label="Password"
                type="password"
                fullWidth
                error={!!errors.password}
                helperText={errors.password?.message}
              />
            )}
            <FormControl fullWidth margin="dense">
              <InputLabel>Groups</InputLabel>
              <Select
                multiple
                value={selectedGroups || ['None']}
                onChange={handleGroupChange}
                renderValue={(selected) => {
                  if (selectedGroups[0] === 'None') {
                    return ['None'];
                  }
                  return groups
                    .filter((group) => selected.includes(group.id))
                    .map((group) => group.name)
                    .join(', ');
                }}
              >
                {groupsLoading && <MenuItem>Loading...</MenuItem>}
                {groupsError && <MenuItem>Error loading groups</MenuItem>}
                {groups.map((group) => (
                  <MenuItem key={group.id} value={group.id}>
                    {group.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Save
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default AdminUsers;
