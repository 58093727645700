import { useFetchChartsQuery } from '../../apis/chart';

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  MenuItem,
  TextField,
  Typography,
  Box,
} from '@mui/material';

const PatientChartsDropdown = ({ patient }) => {
  const navigate = useNavigate();
  const [name, setName] = useState(patient?.name || '');
  const [selectedChart, setSelectedChart] = useState('');
  useEffect(() => {
    if (patient?.name) {
      setName(patient.name);
    }
  }, [patient]);

  const { data, error, isLoading } = useFetchChartsQuery({ patient: name });

  if (isLoading) return <p>Loading charts...</p>;
  if (error) return <p>Error loading charts</p>;

  const charts = data?.data || [];

  if (charts.length === 0) {
    return <p>No charts available for this patient.</p>;
  }

  const handleChange = (event) => {
    const chartId = event.target.value;
    setSelectedChart(chartId);
    navigate(`/chart/${chartId}`);
  };

  return (
    <>
      <Box>
        <Typography variant="h6" gutterBottom>
          Other Charts:
        </Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <TextField
            select
            label="Select Another Chart"
            value={selectedChart}
            onChange={handleChange}
            variant="outlined"
            size="small"
            sx={{ minWidth: 250 }}
          >
            {charts.map((chart) => (
              <MenuItem key={chart.uuid} value={chart.uuid}>
                {chart.file_gcs_location}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Box>
    </>
  );
};

export default PatientChartsDropdown;
