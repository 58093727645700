import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { DataGrid } from '@mui/x-data-grid';
import { useFetchChartsQuery, useDeleteChartMutation } from '../../apis/chart';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
  Button,
} from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LaunchIcon from '@mui/icons-material/Launch';
import DeleteIcon from '@mui/icons-material/Delete';
import dayjs from 'dayjs';
import {
  filenameComparator,
  FIRST_PASS,
  workflowTableStyles,
} from '../../utils';
import theme from '../../styles/theme';
import { useGetUserQuery } from '../../apis/users';

const FirstPassCharts = ({ chartSource, debouncedPatientFilter }) => {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 50,
  });

  const { data, error, isLoading } = useFetchChartsQuery({
    page: paginationModel.page + 1,
    pageSize: paginationModel.pageSize,
    wf_step: FIRST_PASS,
    patient: debouncedPatientFilter,
    chartSource,
  });

  const { data: currentUser } = useGetUserQuery();

  const [deleteChart] = useDeleteChartMutation();
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const handlePaginationModelChange = useCallback(
    (newModel) => {
      if (newModel.pageSize !== paginationModel.pageSize) {
        setPaginationModel({ ...newModel, page: 0 });
      } else {
        setPaginationModel(newModel);
      }
    },
    [paginationModel.pageSize],
  );

  const handleDelete = async () => {
    await deleteChart(selectedId);
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedId(null);
  };

  const navigate = useNavigate();
  const handleOpenDetails = (id) => {
    navigate(`/chart/${id}`);
  };
  const handleOpenDetailsNewTab = (id) => {
    window.open(`/chart/${id}`, '_blank');
  };

  const isCoder = currentUser?.groups?.some((group) => group === 'Coder');

  const extractConditions = (codedChart) => {
    if (!codedChart || !codedChart.coded_chart_code_references) return '';
    return codedChart.coded_chart_code_references
      .filter((code) => code.condition)
      .map((code) => code.condition.name)
      .join(', ');
  };

  const rows =
    data?.data?.map((chart) => {
      const humanCodedCharts = chart.coded_charts.filter(
        (cc) => cc.source === 'Human',
      );
      const coderInfos = humanCodedCharts.map((cc) => ({
        name: cc.coder_details?.username || `Coder#${cc.coder_details?.id}`,
        isProcessed: cc.is_processed,
      }));

      const relevantAssignments =
        chart.chart_assignments?.filter(
          (asm) => asm.workflow_step === FIRST_PASS,
        ) || [];
      const assignedToUsernames = relevantAssignments.map(
        (asm) => asm.user_details?.username || `user#${asm.user}`,
      );

      const aiCodedChart = chart?.coded_charts.find((c) => c.source === 'AI');
      return {
        id: chart.uuid,
        date_of_service: chart.date_of_service,
        file_gcs_location: chart.file_gcs_location,
        patient: chart.patient ? `${chart.patient.name}` : 'Unknown',
        conditions: extractConditions(aiCodedChart),
        coderInfos, // array of { name, isProcessed }
        assignedToUsernames, // array of strings
      };
    }) || [];

  // Basic columns (always shown)
  const baseColumns = [
    {
      field: 'date_of_service',
      headerName: 'DOS',
      flex: 1,
      renderCell: (params) =>
        dayjs(params.row.date_of_service).format('MM-DD-YYYY'),
    },
    {
      field: 'patient',
      headerName: 'Patient',
      flex: 1,
    },
    {
      field: 'file_gcs_location',
      headerName: 'Filename',
      flex: 2,
      sortComparator: filenameComparator,
    },
    {
      field: 'conditions',
      headerName: 'AI Identified Conditions',
      flex: 2,
    },
  ];

  // Coded By column (hidden if isCoder = true)
  const codedByColumn = {
    field: 'coded_by',
    headerName: 'Coded by',
    flex: 1,
    renderCell: (params) => {
      const coderInfos = params.row.coderInfos || [];
      return (
        <Box sx={{ display: 'flex', gap: '4px' }}>
          {coderInfos.map(({ name, isProcessed }) => {
            const bgColor = isProcessed
              ? theme.palette.workflows.green
              : theme.palette.workflows.yellow;
            return (
              <Tooltip key={name} title={name}>
                <Box
                  sx={{
                    width: '48px',
                    height: '48px',
                    borderRadius: '50%',
                    backgroundColor: bgColor,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '0.8rem',
                    cursor: 'pointer',
                  }}
                >
                  {name.slice(0, 3).toUpperCase()}
                </Box>
              </Tooltip>
            );
          })}
        </Box>
      );
    },
  };

  // Assigned To column (hidden if isCoder = true)
  const assignedToColumn = {
    field: 'assigned_to',
    headerName: 'Assigned to',
    flex: 1,
    renderCell: (params) => {
      const coderUsernames = params.row.assignedToUsernames || [];
      return (
        <Box sx={{ display: 'flex', gap: '4px' }}>
          {coderUsernames.map((username) => (
            <Tooltip key={username} title={`${username}`}>
              <Box
                sx={{
                  width: '48px',
                  height: '48px',
                  borderRadius: '50%',
                  backgroundColor: '#eee',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '0.8rem',
                  cursor: 'pointer',
                }}
              >
                {username.slice(0, 3).toUpperCase()}
              </Box>
            </Tooltip>
          ))}
        </Box>
      );
    },
  };

  // Action columns
  const actionColumns = [
    {
      field: 'open',
      headerName: 'Open',
      sortable: false,
      width: 80,
      renderCell: (params) => (
        <IconButton onClick={() => handleOpenDetails(params.id)}>
          <OpenInNewIcon />
        </IconButton>
      ),
    },
    {
      field: 'openNewTab',
      headerName: 'New Tab',
      sortable: false,
      width: 80,
      renderCell: (params) => (
        <Tooltip title="New Tab">
          <IconButton onClick={() => handleOpenDetailsNewTab(params.id)}>
            <LaunchIcon />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      field: 'delete',
      headerName: 'Delete',
      sortable: false,
      width: 80,
      renderCell: (params) => (
        <IconButton
          onClick={() => {
            setSelectedId(params.id);
            setOpen(true);
          }}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  const columns = [
    ...baseColumns,
    ...(isCoder ? [] : [codedByColumn, assignedToColumn]),
    ...actionColumns,
  ];

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error?.message}</div>;

  return (
    <Box>
      <DataGrid
        rows={rows}
        columns={columns}
        pagination
        paginationMode="server"
        rowCount={data?.total || 0}
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationModelChange}
        pageSizeOptions={[25, 50, 100]}
        initialState={{
          sorting: {
            sortModel: [{ field: 'file_gcs_location', sort: 'asc' }],
          },
        }}
        sx={workflowTableStyles}
        data-testid="charts-table"
      />

      {/* Deletion Dialog */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this chart? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default FirstPassCharts;
