import React from 'react';
import {
  Modal,
  Paper,
  Typography,
  Box,
  Button,
  TextField,
} from '@mui/material';

export default function RationaleModal({
  open,
  onClose,
  rationaleData,
  onRationaleChange,
  onSubmit,
}) {
  return (
    <Modal open={open} onClose={onClose}>
      <Paper
        sx={{
          width: '80%',
          margin: 'auto',
          marginTop: '5%',
          padding: 4,
        }}
      >
        <Typography variant="h6" gutterBottom>
          Finalize Codes
        </Typography>
        <Box
          sx={{
            maxHeight: '50vh',
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          {rationaleData?.length > 0 ? (
            rationaleData.map((item, index) => (
              <Box
                key={item.code}
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
                  gap: 2,
                }}
              >
                <Box sx={{ flex: 1 }}>
                  <Typography variant="body1">{item.code}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    {item.description}
                  </Typography>
                </Box>
                <Box sx={{ flex: 2 }}>
                  <TextField
                    label="Rationale"
                    value={item?.rationale ?? ''}
                    onChange={(e) => onRationaleChange(index, e.target.value)}
                    fullWidth
                  />
                </Box>
              </Box>
            ))
          ) : (
            <Typography variant="body2">No codes to finalize.</Typography>
          )}
        </Box>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={onSubmit}
          sx={{ marginTop: 3 }}
        >
          Submit
        </Button>
      </Paper>
    </Modal>
  );
}
