import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Typography,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
} from '@mui/material';
import { useUpdatePatientMutation } from '../../apis/patients';

const PatientDetail = ({ patient }) => {
  const [name, setName] = useState(patient?.name || '');
  const [dob, setDob] = useState(patient?.dob || '');
  const [updatePatient, { isLoading, error }] = useUpdatePatientMutation();
  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    setName(patient?.name);
    setDob(patient?.dob);
  }, [patient?.name, patient?.dob]);

  const handleSubmit = async () => {
    try {
      const patientUpdateData = { ...patient, name, dob };
      await updatePatient(patientUpdateData);
      setAlertMessage('Successfully saved patient details');
    } catch (err) {
      console.error('Error saving patient details:', err);
    }
  };

  return (
    <>
      <Box>
        <Typography variant="h6" gutterBottom>
          Demographics:
        </Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <TextField
            name="name"
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value || '')}
            size="small"
          />
          <TextField
            name="dob"
            label="DOB"
            value={dob}
            onChange={(e) => setDob(e.target.value || '')}
            size="small"
          />
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            disabled={isLoading}
            startIcon={isLoading ? <CircularProgress size={14} /> : null}
          >
            Save patient
          </Button>
        </Box>
        {error && (
          <Typography color="error" sx={{ marginTop: 2 }}>
            Failed to save patient details. Please refresh the page and try
            again.
          </Typography>
        )}
        {/* Snackbar */}
        <Snackbar
          open={!!alertMessage}
          autoHideDuration={3000}
          onClose={() => setAlertMessage('')}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setAlertMessage('')}
            severity="success"
            sx={{ width: '100%' }}
          >
            {alertMessage}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
};

export default PatientDetail;
