import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Snackbar,
  Typography,
} from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CodeCard from '../CodeCard';
import ProcessingTimeModal from '../ProcessingTimeModal';
import {
  useCreateOrUpdateCodedChartMutation,
  useFetchChartDetailsQuery,
  useFetchSlowChartDataQuery,
} from '../../../apis/chart';

const FirstPassFinalizedCodes = ({
  completedCodes,
  handleRemoveFromCompleted,
  aiIdentifiedConditions,
  userSelectedCodes,
  onDragEnd,
  codeReviewStatus,
  humanCodedChart,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [createOrUpdateCodedChart, { isLoading: isLoadingCodedChartMutation }] =
    useCreateOrUpdateCodedChartMutation();
  const {
    data: chart,
    isFetching: isFetchingChart,
    refetch: refetchDetail,
  } = useFetchChartDetailsQuery(id);
  const { data: slowData } = useFetchSlowChartDataQuery(id);

  const handleFinalizeClick = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleModalSubmit = async (time) => {
    try {
      await createOrUpdateCodedChart({
        id: humanCodedChart?.uuid,
        chart: chart.uuid,
        source: 'Human',
        confirmed_codes: completedCodes,
        processing_time: time,
        is_processed: true,
      }).unwrap();
      refetchDetail();
      setModalOpen(false);
      setAlertMessage('Successfully finalized codes');
      setAlertSeverity('success');
      if (slowData?.next_chart) {
        navigate(`/chart/${slowData?.next_chart}`);
      }
    } catch (error) {
      console.error('Failed to finalize codes:', error);
      const errorMessage =
        error?.data?.detail ||
        'Failed to finalize codes. Please refresh the page and try again.';
      setAlertMessage(errorMessage);
      setAlertSeverity('error');
    }
  };

  const handleUnlockCodes = async () => {
    try {
      await createOrUpdateCodedChart({
        id: humanCodedChart?.uuid,
        chart: chart.uuid,
        is_processed: false,
      }).unwrap();
      refetchDetail();
      setAlertMessage('Successfully unlocked codes!');
      setAlertSeverity('success');
    } catch (error) {
      console.error('Failed to unlock codes:', error);
      setAlertMessage(
        'Failed to unlock codes. Please refresh the page and try again.',
      );
      setAlertSeverity('error');
    }
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginLeft: 2,
          marginBottom: 1,
        }}
      >
        <Typography variant="h6">Finalized Codes</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={
            humanCodedChart?.is_processed
              ? handleUnlockCodes
              : handleFinalizeClick
          }
          disabled={isLoadingCodedChartMutation || isFetchingChart}
          startIcon={
            isLoadingCodedChartMutation || isFetchingChart ? (
              <CircularProgress size={14} />
            ) : null
          }
        >
          {humanCodedChart?.is_processed ? 'Unlock' : 'Finalize'}
        </Button>
        <Snackbar
          open={!!alertMessage}
          autoHideDuration={3000}
          onClose={() => setAlertMessage('')}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setAlertMessage('')}
            severity={alertSeverity}
            sx={{ width: '100%' }}
          >
            {alertMessage}
          </Alert>
        </Snackbar>
      </Box>
      <Box
        sx={{
          padding: 2,
          border: '1px solid #ccc',
          backgroundColor: '#f9f9f9',
          height: '100%',
        }}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="completedCodes">
            {(provided) => (
              <Box
                ref={provided.innerRef}
                {...provided.droppableProps}
                sx={{ minHeight: '80%', overflowY: 'auto' }}
              >
                {completedCodes.map((code, index) => {
                  const codeKey = code.code;
                  const status = codeReviewStatus?.[chart.uuid]?.[codeKey];
                  const reviewResult = status?.reviewResult;

                  return (
                    <Draggable
                      key={codeKey}
                      draggableId={codeKey}
                      index={index}
                    >
                      {(provided) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          sx={{ position: 'relative', paddingBottom: '1rem' }}
                        >
                          <CodeCard
                            code={code}
                            action={handleRemoveFromCompleted}
                            chevronLeft
                            aiIdentifiedConditions={aiIdentifiedConditions}
                            userSelectedCodes={userSelectedCodes}
                            reviewResult={reviewResult}
                            chart={chart}
                          />
                        </Box>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>

        <ProcessingTimeModal
          open={modalOpen}
          onClose={handleModalClose}
          onSubmit={handleModalSubmit}
        />
      </Box>
    </Box>
  );
};

export default FirstPassFinalizedCodes;
