import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Grid,
  Typography,
  Box,
  Paper,
  Button,
  Chip,
  Tabs,
  Tab,
  Snackbar,
  Alert,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  useFetchChartDetailsQuery,
  useFetchSlowChartDataQuery,
} from '../../apis/chart';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import HighlightedContent from '../ChartContent/HighlightDisplay';
import PDFViewer from '../ChartContent/PDFViewer';
import {
  COMPLETED,
  FIRST_PASS,
  formatWorkflowStep,
  OUT_OF_SCOPE,
  SECOND_PASS,
  TRIAGE,
} from '../../utils';
import ChartDetailsEditable from './ChartDetailsEditable';
import theme from '../../styles/theme';
import FirstPassCompletedCodesBuilder from '../CodesBuilder/FirstPass/FirstPassCompletedCodesBuilder';
import SecondPassCompletedCodesBuilder from '../CodesBuilder/SecondPass/SecondPassCompletedCodesBuilder';
import { useGetUserQuery } from '../../apis/users';
import TriageModal from './TriageModal';
import PatientDetail from './PatientDetail';
import PatientChartsDropdown from './PatientChartsDropdown';

const ChartDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: chart, error: chartError } = useFetchChartDetailsQuery(id);
  const { data: slowData } = useFetchSlowChartDataQuery(id);
  const { data: currentUser } = useGetUserQuery();

  const [highlightedText, setHighlightedText] = useState(null);
  const [completedCodesKey, setCompletedCodesKey] = useState(Date.now());
  const [contentTab, setContentTab] = useState(0);
  const [isTriageModalOpen, setIsTriageModalOpen] = useState(false);

  const workflowStepParam = chart?.workflow_state;

  const errorMessage = chartError?.data?.detail
    ? chartError?.data?.detail ||
      'Unable to fetch chart. Please refresh the page and try again. If the issue persists, contact the Eng team.'
    : '';

  useEffect(() => {
    setCompletedCodesKey(Date.now());
  }, [id]);

  const handleCodeClick = (supportingText) => {
    setHighlightedText(supportingText);
  };

  const navigateToChart = (chartId) => {
    if (chartId) {
      navigate(`/chart/${chartId}`);
    }
  };

  const handleContentTabChange = (event, newValue) => {
    setContentTab(newValue);
  };

  const handleTriageModalClose = () => {
    setIsTriageModalOpen(false);
  };

  // Choose the human-coded chart for the current user
  const humanCodedChart = useMemo(() => {
    if (!chart?.coded_charts || !currentUser?.username) return null;

    if (workflowStepParam === COMPLETED) {
      // In completed state we want to use the final coded chart instead of the user specific chart
      return chart?.final_coded_chart
    }

    return (
      chart.coded_charts.find(
        (cc) =>
          cc.source === 'Human' &&
          cc.coder_details?.username === currentUser.username,
      ) || null
    );
  }, [chart, currentUser, workflowStepParam]);

  const statusColor = humanCodedChart?.is_processed ? 'success' : 'error';

  if (errorMessage) {
    return (
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          width: '100%',
          height: '100vh',
          padding: '20px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Grid container spacing={2} alignItems="stretch" sx={{ flex: 1 }}>
          <Grid item xs={12}>
            <Paper elevation={3} sx={{ padding: 3, borderRadius: 2 }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  gap: 2,
                }}
              >
                {/* Chart Workflow State */}
                <Box sx={{ alignItems: 'center' }}>
                  <Typography variant="h6">Workflow State:</Typography>
                  <Box
                    sx={{
                      padding: '4px 12px',
                      borderRadius: '16px',
                      backgroundColor: theme.palette.text.primary,
                      color: theme.palette.background.default,
                      fontWeight: 'bold',
                      fontSize: '1.2rem',
                      display: 'inline-block',
                    }}
                  >
                    {formatWorkflowStep(workflowStepParam)}
                  </Box>
                </Box>
                {/* Demographics */}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                  }}
                >
                  <PatientDetail patient={chart?.patient} />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                  }}
                >
                  <PatientChartsDropdown patient={chart?.patient} />
                </Box>
                <Box sx={{ alignItems: 'center' }}>
                  <Typography variant="h6" gutterBottom>
                    Chart Actions:
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                      }}
                    >
                      {/* Re-triage Button*/}
                      {(workflowStepParam === FIRST_PASS ||
                        workflowStepParam === SECOND_PASS) &&
                        chart && (
                          <>
                            {isTriageModalOpen && (
                              <TriageModal
                                chart={chart}
                                onSave={() =>
                                  navigateToChart(slowData.next_chart)
                                }
                                isOpen={isTriageModalOpen}
                                onClose={handleTriageModalClose}
                              />
                            )}
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => setIsTriageModalOpen(true)}
                            >
                              Re-triage
                            </Button>
                          </>
                        )}
                      {/* Previous and Next Buttons */}
                      {slowData?.previous_chart && (
                        <Button
                          onClick={() =>
                            navigateToChart(slowData.previous_chart)
                          }
                          variant="contained"
                          color="secondary"
                        >
                          <ArrowBackIosIcon sx={{ color: 'white' }} />
                          <Typography
                            variant="body1"
                            sx={{ marginLeft: 1, color: 'white' }}
                          >
                            Previous Chart
                          </Typography>
                        </Button>
                      )}
                      {slowData?.next_chart && (
                        <Button
                          onClick={() => navigateToChart(slowData.next_chart)}
                          variant="contained"
                          color="secondary"
                        >
                          <Typography
                            variant="body1"
                            sx={{ marginRight: 1, color: 'white' }}
                          >
                            Next Chart
                          </Typography>
                          <ArrowForwardIosIcon
                            sx={{ marginLeft: 1, color: 'white' }}
                          />
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Grid>

          {/* Chart Details and Content */}
          <Grid
            item
            xs={12}
            md={5}
            sx={{ display: 'flex', flexDirection: 'column', height: '80vh' }}
          >
            <Paper
              elevation={3}
              sx={{ padding: 3, borderRadius: 2, flex: 1, overflow: 'auto' }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h6" gutterBottom>
                  <strong>File:</strong> {chart?.file_gcs_location || 'Unknown'}
                </Typography>
                {workflowStepParam && workflowStepParam === FIRST_PASS && (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body1" sx={{ marginRight: 1 }}>
                      <strong>Coding Status:</strong>
                    </Typography>
                    <Chip
                      label={
                        humanCodedChart?.is_processed
                          ? 'Finalized'
                          : 'Unprocessed'
                      }
                      color={statusColor}
                      sx={{ fontSize: '1rem' }}
                      data-testid="chart-status-label"
                    />
                  </Box>
                )}
                {workflowStepParam && workflowStepParam === SECOND_PASS && (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body1" sx={{ marginRight: 1 }}>
                      <strong>Review Status:</strong>
                    </Typography>
                    <Chip
                      label={
                        humanCodedChart?.is_processed
                          ? 'Confirmed'
                          : 'Unprocessed'
                      }
                      color={statusColor}
                      sx={{ fontSize: '1rem' }}
                      data-testid="chart-status-label"
                    />
                  </Box>
                )}
              </Box>

              <br />

              <Tabs
                value={contentTab}
                onChange={handleContentTabChange}
                aria-label="content tabs"
              >
                <Tab label="Body" />
                <Tab label="Document" />
              </Tabs>
              {contentTab === 0 && chart && (
                <Typography variant="body1" sx={{ flex: 1 }}>
                  <HighlightedContent
                    highlightedText={highlightedText}
                    chart={chart}
                  />
                </Typography>
              )}
              {contentTab === 1 && slowData?.gcs_file_link && (
                <Box sx={{ flex: 1, overflow: 'auto' }}>
                  <PDFViewer fileUrl={slowData.gcs_file_link} />
                </Box>
              )}
            </Paper>
          </Grid>

          <Grid
            item
            xs={12}
            md={7}
            sx={{ display: 'flex', flexDirection: 'column', height: '80vh' }}
          >
            <Paper
              elevation={3}
              sx={{ padding: 3, borderRadius: 2, flex: 1, overflow: 'auto' }}
            >
              {workflowStepParam &&
                (workflowStepParam === TRIAGE ||
                  workflowStepParam === OUT_OF_SCOPE) &&
                chart && (
                  <ChartDetailsEditable
                    chart={chart}
                    onSave={() => navigateToChart(slowData.next_chart)}
                  />
                )}

              {workflowStepParam && workflowStepParam === FIRST_PASS && (
                <FirstPassCompletedCodesBuilder
                  key={completedCodesKey}
                  chart={chart}
                  handleCodeClick={handleCodeClick}
                  humanCodedChart={humanCodedChart}
                  onSave={() => navigateToChart(slowData.next_chart)}
                />
              )}
              {workflowStepParam &&
                (workflowStepParam === SECOND_PASS ||
                  workflowStepParam === COMPLETED) && (
                  <SecondPassCompletedCodesBuilder
                    key={completedCodesKey}
                    chart={chart}
                    handleCodeClick={handleCodeClick}
                    humanCodedChart={humanCodedChart}
                    onSave={() => navigateToChart(slowData.next_chart)}
                  />
                )}
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default ChartDetails;
