export const formatCurrency = (value) => {
    if (value === 'N/A') return value;
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value);
};

export const titleCase = (str) => {
    return str.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
};

export const extractFilenameDetails = (filename) => {
    const match = filename.match(/charts\/([\w-]+)\.pdf_pages_(\d+)-(\d+)\.pdf/);
    if (!match) return { uuid: '', startPage: 0, endPage: 0 };

    const [, uuid, startPage, endPage] = match;
    return { uuid, startPage: parseInt(startPage, 10), endPage: parseInt(endPage, 10) };
};


export const filenameComparator = (v1, v2) => {
    const { uuid: uuid1, startPage: start1 } = extractFilenameDetails(v1);
    const { uuid: uuid2, startPage: start2 } = extractFilenameDetails(v2);

    if (uuid1 < uuid2) return -1;
    if (uuid1 > uuid2) return 1;
    return start1 - start2;
};

export const formatWorkflowStep = (step) => {
    if (!step) return '';

    return step
        .replace(/_/g, ' ') // Replace underscores with spaces
        .split(' ')         // Split the string into words
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
        .join(' ');         // Join the words back together
};

export const workflowTableStyles = {
    '& .MuiDataGrid-root': {
        border: '10px solid #ddd', // Thicker border for the table
        borderRadius: '8px', // Optional: Rounded corners for the table
    },
    '& .MuiDataGrid-row:nth-of-type(odd)': {
        backgroundColor: '#f9f9f9', // Light gray for odd rows
    },
    '& .MuiDataGrid-row:nth-of-type(even)': {
        backgroundColor: '#fff', // White for even rows
    },
    '& .MuiDataGrid-row:hover': {
        backgroundColor: '#f5f5f5', // Slightly darker gray on hover
    },
};




export const TRIAGE = 'triage'
export const FIRST_PASS = 'first_pass'
export const SECOND_PASS = 'second_pass'

export const COMPLETED = 'completed'
export const OUT_OF_SCOPE = 'out_of_scope'


export const DEFINITELY = 'Definitely'
