import React, { useState } from 'react';
import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CodeCard from '../CodeCard';
import theme from '../../../styles/theme';
import ConditionHeader from '../ConditionHeader';
import { DEFINITELY } from '../../../utils';

const FirstPassConditionsList = ({
  aiIdentifiedConditions,
  completedCodes,
  userSelectedCodes,
  handleMoveToCompleted,
  handleCodeClick,
  highlightedText,
  chart,
}) => {
  const [otherCodesOpen, setOtherCodesOpen] = useState(false);

  const userAvailableCodes = userSelectedCodes.map((code) => ({
    ...code,
    condition: 'User Added',
    highlight_text: '',
    rationale: '',
  }));

  const combinedAvailableCodes = aiIdentifiedConditions
    .flatMap((group) =>
      group.codes.map((code) => ({
        ...code,
        condition: group.condition,
        highlight_text: group.highlight_text,
        rationale: group.rationale,
        full_ref: group.full_ref?.[0], // existing logic
      })),
    )
    .concat(userAvailableCodes);

  const filteredAvailableCodes = combinedAvailableCodes.filter(
    (code) => !completedCodes.some((done) => done.code === code.code),
  );

  const hccCodes = filteredAvailableCodes.filter(
    (c) => c?.hcc_mapping?.has_hcc && c?.full_ref?.appropriate === DEFINITELY,
  );
  const nonHccCodes = filteredAvailableCodes.filter(
    (c) => !c?.hcc_mapping?.has_hcc || c?.full_ref?.appropriate !== DEFINITELY,
  );

  const groupedCodes = nonHccCodes.reduce((acc, code) => {
    const condition = code.condition;
    const existingGroup = acc.find((g) => g.condition === condition);
    if (existingGroup) {
      existingGroup.codes.push(code);
    } else {
      acc.push({
        condition,
        codes: [code],
        highlight_text: code.highlight_text,
        rationale: code.rationale,
      });
    }
    return acc;
  }, []);

  // Separate user "User Added" group from AI
  const userAddedGroup = groupedCodes.find((g) => g.condition === 'User Added');
  const aiGroupedGroups = groupedCodes.filter(
    (g) => g.condition !== 'User Added',
  );

  const toggleOtherCodes = () => {
    setOtherCodesOpen((prev) => !prev);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ height: '100%', overflowY: 'auto' }}>
        <Box sx={{ mb: 2, paddingBottom: '10px' }}>
          <Typography
            variant="h6"
            sx={{
              display: 'flex',
              alignItems: 'center',
              paddingBottom: '10px',
            }}
          >
            <AssignmentIcon fontSize="small" sx={{ mr: 1 }} />
            {`For Review (${hccCodes.length})`}
          </Typography>

          {hccCodes.length > 0 &&
            hccCodes
              .sort((a, b) => a.code.localeCompare(b.code))
              .map((codeObj) => {
                const codeKey = codeObj.code;
                return (
                  <Box key={codeKey} sx={{ position: 'relative', mb: 1 }}>
                    <CodeCard
                      code={codeObj}
                      chart={chart}
                      action={handleMoveToCompleted}
                      aiIdentifiedConditions={aiIdentifiedConditions}
                      userSelectedCodes={userSelectedCodes}
                      handleCodeClick={handleCodeClick}
                      reviewable={true}
                    />
                  </Box>
                );
              })}
        </Box>

        {userAddedGroup && userAddedGroup.codes.length > 0 && (
          <Box sx={{ mb: 2, paddingBottom: '10px' }}>
            <Typography variant="h6" sx={{ marginBottom: 1 }}>
              User Added
            </Typography>
            {userAddedGroup.codes
              .sort((a, b) => a.code.localeCompare(b.code))
              .map((codeObj) => {
                const codeKey = codeObj.code;
                return (
                  <Box key={codeKey} sx={{ position: 'relative', mb: 1 }}>
                    <CodeCard
                      code={codeObj}
                      chart={chart}
                      action={handleMoveToCompleted}
                      aiIdentifiedConditions={aiIdentifiedConditions}
                      userSelectedCodes={userSelectedCodes}
                      handleCodeClick={handleCodeClick}
                    />
                  </Box>
                );
              })}
          </Box>
        )}
        {aiGroupedGroups.length > 0 && (
          <Box sx={{ mb: 2, paddingBottom: '10px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography variant="h6" sx={{ mr: 2 }}>
                {`Other Codes (${nonHccCodes.length})`}
              </Typography>
              <Button variant="text" onClick={toggleOtherCodes}>
                {otherCodesOpen ? 'Hide' : 'Show'}
              </Button>
            </Box>

            {otherCodesOpen &&
              aiGroupedGroups.map((group) => (
                <Card key={group.condition} sx={{ mb: 2 }}>
                  <CardContent>
                    <ConditionHeader
                      group={group}
                      handleCodeClick={handleCodeClick}
                      highlightedText={highlightedText}
                      isExpanded={true}
                    />
                    {group.codes
                      .sort((a, b) => a.code.localeCompare(b.code))
                      .map((codeObj) => {
                        const codeKey = codeObj.code;
                        return (
                          <Box
                            key={codeKey}
                            sx={{ position: 'relative', mb: 1 }}
                          >
                            <CodeCard
                              key={codeKey}
                              code={codeObj}
                              chart={chart}
                              action={handleMoveToCompleted}
                              aiIdentifiedConditions={aiIdentifiedConditions}
                              userSelectedCodes={userSelectedCodes}
                              handleCodeClick={handleCodeClick}
                            />
                          </Box>
                        );
                      })}
                  </CardContent>
                </Card>
              ))}
          </Box>
        )}
      </Box>
    </ThemeProvider>
  );
};

export default FirstPassConditionsList;
