import React, { useState, useEffect, useCallback } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useFetchChartsQuery, useDeleteChartMutation } from '../../apis/chart';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LaunchIcon from '@mui/icons-material/Launch';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { filenameComparator, TRIAGE, workflowTableStyles } from '../../utils';

/**
 * Single TriageCharts component
 * - Fetches triage charts
 * - Renders them in a DataGrid
 * - Handles deletion, open/new tab details
 */
const TriageCharts = ({ chartSource, debouncedPatientFilter }) => {
  // Pagination state
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 50,
  });

  // RTK Query: fetch triage charts
  // We pass wf_step="triage" (or whatever param your backend expects)
  const { data, error, isLoading, isFetching, refetch } = useFetchChartsQuery({
    page: paginationModel.page + 1,
    pageSize: paginationModel.pageSize,
    wf_step: TRIAGE,
    patient: debouncedPatientFilter,
    chartSource,
  });

  // For deletion
  const [deleteChart] = useDeleteChartMutation();
  const navigate = useNavigate();

  // Deletion dialog state
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  // Pagination changes
  const handlePaginationModelChange = useCallback(
    (newModel) => {
      if (newModel.pageSize !== paginationModel.pageSize) {
        setPaginationModel({ ...newModel, page: 0 });
      } else {
        setPaginationModel(newModel);
      }
    },
    [paginationModel.pageSize],
  );

  // Refetch on pagination changes
  useEffect(() => {
    refetch();
  }, [paginationModel, refetch]);

  // Handle deletion
  const handleDelete = async () => {
    await deleteChart(selectedId);
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedId(null);
  };

  // Chart detail navigation
  const handleOpenDetails = (id) => {
    navigate(`/chart/${id}`);
  };

  const handleOpenDetailsNewTab = (id) => {
    window.open(`/chart/${id}`, '_blank');
  };

  // Columns
  const columns = [
    {
      field: 'created_time',
      headerName: 'Upload Date',
      flex: 1,
      renderCell: (params) =>
        dayjs(params.row.created_time).format('MM-DD-YYYY h:mm A'),
    },
    {
      field: 'date_of_service',
      headerName: 'Dates of Service',
      flex: 1,
      renderCell: (params) => {
        const encounters = params.row.encounters || [];
        return encounters
          .map((encounter) =>
            dayjs(encounter.date_of_service_start).format('MM-DD-YYYY'),
          )
          .join(', ');
      },
    },

    {
      field: 'patient',
      headerName: 'Patient',
      flex: 1,
    },
    {
      field: 'file_gcs_location',
      headerName: 'Filename',
      flex: 2,
      valueGetter: (value, row) => row.file_gcs_location,
      sortComparator: filenameComparator,
    },
    {
      field: 'open',
      headerName: 'Open',
      sortable: false,
      width: 80,
      renderCell: (params) => (
        <IconButton
          onClick={() => handleOpenDetails(params.id)}
          data-testid={`chart-detail-button-${params.id}`}
        >
          <OpenInNewIcon />
        </IconButton>
      ),
    },
    {
      field: 'openNewTab',
      headerName: 'New Tab',
      sortable: false,
      width: 80,
      renderCell: (params) => (
        <Tooltip title="New Tab">
          <IconButton
            onClick={() => handleOpenDetailsNewTab(params.id)}
            aria-label="Open in New Tab"
          >
            <LaunchIcon />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      field: 'delete',
      headerName: 'Delete',
      sortable: false,
      width: 80,
      renderCell: (params) => (
        <IconButton
          onClick={() => {
            setSelectedId(params.id);
            setOpen(true);
          }}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  // Transform API data -> rows
  const rows =
    data?.data?.map((chart) => ({
      id: chart.uuid,
      encounters: chart.encounters,
      chart_source: chart.source,
      created_time: chart.created_time,
      file_gcs_location: chart.file_gcs_location,
      patient: chart.patient ? `${chart.patient.name}` : 'Unknown',
      chart_type: chart.chart_type,
    })) || [];

  // Loading / error states
  if (isLoading || isFetching) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <>
      <DataGrid
        pagination
        rows={rows}
        columns={columns}
        rowsPerPageOptions={[25, 50, 100]}
        paginationMode="server"
        rowCount={data?.total || 0}
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationModelChange}
        pageSizeOptions={[25, 50, 100]}
        data-testid="charts-table"
        striped
        initialState={{
          sorting: {
            sortModel: [
              { field: 'file_gcs_location', sort: 'asc' },
              { field: 'patient', sort: 'asc' },
            ],
          },
        }}
        sx={workflowTableStyles}
      />

      {/* Deletion Dialog */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this chart? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TriageCharts;
